<div class="z-fx-col z-fx-start-center z-fx-gap-16 why-finder-container">
  <ng-container *ngIf="questionData?.whyFinder?.maxQuestions !== 1; else simpleInterview">
    <div class="z-fx-col z-fx-auto z-fx-gap-8 messages">
      <ng-container *ngFor="let message of messages; trackBy: trackByMessage; let i = index">
        <div
          *ngIf="
            (message.role === 'assistant' || message.role === 'user') && (i >= messages.length - 3 || showAllMessages)
          "
          class="message"
          [class.interviewee]="message.role === 'user'"
          [class.interviewer]="message.role === 'assistant'"
          [style.color]="message.role === 'user' ? (ss.colors | async).text80 : (ss.colors | async).text"
        >
          {{ message?.message }}
        </div>
      </ng-container>
    </div>
    <div *ngIf="loading$ | async" class="dot-typing" [style.--text-color]="(ss.colors | async).text80"></div>
    <div
      *ngIf="!messages?.length && (loading$ | async) === false"
      class="z-fx z-fx-center-center"
      [style.color]="(ss.colors | async).text80"
    >
      <mat-icon class="error-icon" fontSet="zefplayer">placeholder</mat-icon>
    </div>
    <div *ngIf="showInput" class="z-fx z-fx-none z-fx-gap-4 input-area">
      <textarea
        class="z-input"
        autocomplete="off"
        autoSize
        [autoSizeMinRows]="1"
        name="free-text"
        [maxLength]="getCharsLimit()"
        #input
        [placeholder]="placeholder || ''"
        [value]="''"
        [style.color]="(ss.colors | async).text80"
        [style.background-color]="(ss.colors | async).mood20"
        (keyup.enter)="addComment(input.value); input.value = ''"
        (input)="onValueChanged()"
      ></textarea>
      <button
        *ngIf="!interviewDone || input.value"
        class="z-fx z-fx-center-center send-button button-size-{{ ss.viewSize | async }}"
        [style.background-color]="(ss.colors | async)?.mood"
        [style.color]="(ss.colors | async)?.primary"
        (click)="addComment(input.value); input.value = ''"
      >
        <mat-icon fontSet="zefplayer">send</mat-icon>
      </button>
      <button
        *ngIf="interviewDone && !input.value"
        class="next-button"
        [style.background-color]="(ss.colors | async)?.button"
        [style.color]="(ss.colors | async)?.buttonContrast"
        (click)="nextQuestion.emit()"
      >
        <ng-container *ngIf="isLast">{{ 'Finish' | translateText: 'finishButton' }}</ng-container>
        <ng-container *ngIf="!isLast">{{ 'Next' | translateText: 'nextButton' }}</ng-container>
      </button>
    </div>

    <button
      *ngIf="showAllMessages === false && messages?.length > 3"
      class="show-all-button"
      [style.background-color]="(ss.colors | async)?.mood"
      [style.color]="(ss.colors | async)?.primary"
      (click)="showAllMessages = true"
    >
      ···
    </button>
  </ng-container>
</div>

<basic-button
  *ngIf="!showInput && questionData?.whyFinder?.maxQuestions !== 1"
  class="no-input-next-button"
  (click)="nextQuestion.emit()"
>
  <ng-container *ngIf="isLast">{{ 'Finish' | translateText: 'finishButton' }}</ng-container>
  <ng-container *ngIf="!isLast">{{ 'Next' | translateText: 'nextButton' }}</ng-container>
</basic-button>

<ng-template #simpleInterview>
  <div class="z-fx-col z-fx-start-start z-fx-gap-24 why-finder-container">
    <div class="z-fx-col z-fx-auto z-fx-gap-8 messages">
      <div class="message" [style.color]="(ss.colors | async).text80">
        {{ messages[0]?.message }}
      </div>
    </div>
    <div *ngIf="loading$ | async" class="z-fx-col z-fx-w100 z-fx-center-center">
      <div class="dot-typing" [style.--text-color]="(ss.colors | async).text80"></div>
    </div>
    <textarea
      class="z-input"
      autocomplete="off"
      autoSize
      [autoSizeMinRows]="5"
      name="free-text"
      [maxLength]="getCharsLimit()"
      #simpleInput
      [placeholder]="placeholder || ''"
      [value]="simpleAnswer"
      [style.color]="(ss.colors | async).text80"
      [style.background-color]="(ss.colors | async).mood20"
      (blur)="saveSimpleAnswer(simpleInput.value)"
      (input)="onValueChanged()"
    ></textarea>
    <basic-button
      class="no-input-next-button"
      [waitNext]="simpleSaveInProgress$ | async"
      (click)="saveSimpleAnswer(simpleInput.value, true)"
    >
      <ng-container *ngIf="isLast">{{ 'Finish' | translateText: 'finishButton' }}</ng-container>
      <ng-container *ngIf="!isLast">{{ 'Next' | translateText: 'nextButton' }}</ng-container>
    </basic-button>
  </div>
</ng-template>
