/**
 * Service for initializing data and services used in player component
 *
 * @unstable
 */

import { environment } from '@env/environment';

import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  shareReplay,
  startWith,
  take,
} from 'rxjs/operators';

import { SessionStorage } from 'ngx-webstorage';

import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';

import { Questions } from '@shared/enums/questions.enum';
import { QuestionData } from '@shared/models/survey.model';

import { PropertyStore } from '@shared/services/property-store.service';
import { AnswersManager } from '@player/shared/services/answers-manager.service';
import { TriggerManager } from '@player/shared/services/trigger-manager.service';
import {
  PlayerAnswer,
  PlayerData,
  PlayerOutcome,
  PlayerState,
  PublicData,
  SessionData,
} from '@player/shared/models/player.model';
import { CommunicationManager } from '@player/shared/services/communication-manager.service';
import { inIframe } from '@shared/utilities/browser.utilities';
import type { SurveyStartConfig } from 'zeffi/lib/models/survey-data.model';
import { getLastValue } from '@shared/operators/share-ref.operator';
import { LanguageManager } from '@player/shared/services/language-manager.service';

@Injectable()
export class PlayerInit {
  public playerState: PlayerState | null = null;
  public isAnonymous = false;

  public root: string | null = null;

  public team: string | null = null;
  public survey: string | null = null;
  public pollSession: string | null = null;

  public data: PlayerData | null = null;

  public release: string | null = null;
  public language: string | null = null;
  public location: string | null = null;

  public initializationDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showAccessibilityBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private accessibilityBannerHide: number = 0;

  private revision: string | null = null;

  readonly surveyStartConfig$ = this.cm.open$.pipe(distinctUntilChanged(), shareReplay(1));

  readonly selectedLanguage$ = this.surveyStartConfig$.pipe(
    map((config) => config?.language),
    filter((language) => !!language),
    distinctUntilChanged(),
  );

  // somehow the route doesn't have them
  private readonly queryParams = new HttpParams({
    fromString: location.search ? location.search.substr(1) : '',
  });

  private restoreState = !!this.queryParams.get('restore');

  private readonly hold$ = this.cm.open$.pipe(
    startWith(!inIframe() || !this.queryParams.get('hold')),
    filter((canOpen) => !!canOpen),
    take(1),
    shareReplay(1),
  );

  readonly accessibilityActive$: Observable<boolean> = this.router.events.pipe(
    filter((event) => event instanceof RouterEvent),
    map((event) => (event as RouterEvent).url.includes('accessible')),
    shareReplay(1),
  );

  @SessionStorage('playerState') playerSessionState?: SessionData<PlayerState>;

  @HostListener('window:beforeunload', ['$event'])
  readonly onBeforeUnload = (event: Event) => {
    if (this.am.hasPendingUploads()) {
      event.preventDefault();
      event.returnValue = true;
    } else {
      delete event.returnValue;
    }
  };

  @HostListener('window:unload')
  @HostListener('window:pagehide')
  readonly shutdown = (): void => {
    this.am.shutdown();
    this.tm.shutdown();
  };

  @HostListener('document:visibilitychange')
  readonly onVisibilityChange = (): void => {
    if (document.visibilityState === 'visible') {
      this.am.resume();
      this.tm.resume();
    } else {
      this.shutdown();
    }
  };

  constructor(
    public route: ActivatedRoute,
    readonly ps: PropertyStore,
    readonly http: HttpClient,
    private am: AnswersManager,
    private tm: TriggerManager,
    private cm: CommunicationManager,
    private router: Router,
    private lm: LanguageManager,
  ) {}

  public initPlayer(): Observable<any> {
    return new Observable<any>((observer) => {
      console.time('render');
      console.time('fetching');

      console.timeEnd('create');

      this.language = this.queryParams.get('lang');

      try {
        this.am.respondentFields = JSON.parse(this.queryParams.get('fields'));
      } catch {}

      this.revision = window.ZEF ? window.ZEF.revision : Math.random().toString();

      if (
        this.route.snapshot.params.public !== 'demo' &&
        (window.location.host.indexOf('192.168.') === 0 || window.location.host.indexOf('localhost') === 0)
      ) {
        this.release = `https:${environment.surveyAddress}/` + `${this.route.snapshot.params.public}/`;
        this.location = `https:${environment.surveyAddress}/` + `${this.route.snapshot.params.public}/`;
      } else if (!window.ZEF && this.queryParams.get('zefSurvey')) {
        this.release = this.queryParams.get('zefSurvey');
        this.location = this.queryParams.get('zefSurvey');
      } else {
        this.release = window.ZEF ? window.ZEF.url || '' : 'assets/testing/';
        this.location = window.ZEF ? window.ZEF.root || window.ZEF.url || '' : 'assets/testing/';
      }

      // TODO: We should check that if the index.html is loaded from different
      // url than the default share link then use relative path.

      const publicData$ =
        window.ZEF && window.ZEF.team.indexOf('surveys-') !== 0
          ? this.http.get(`${environment.surveyAddress}/public/${window.ZEF.team}.json`).pipe(catchError(() => of({})))
          : of({});

      const playerData$ = this.http.get(`${this.location || this.release}settings.json?${this.revision}`);

      this.cm.open$.subscribe((surveyStartConfig: SurveyStartConfig) => {
        if (surveyStartConfig?.respondentFields !== undefined) {
          this.am.respondentFields = surveyStartConfig.respondentFields;
        }

        if (surveyStartConfig?.accessibilityMode && !getLastValue(this.accessibilityActive$)) {
          this.router.navigate(['accessible'], { relativeTo: this.route });
        }
      });

      combineLatest([playerData$, publicData$])
        .pipe(concatMap(([playerData, publicData]) => this.hold$.pipe(mapTo([playerData, publicData]))))
        .subscribe(([playerData, publicData]: [PlayerData, PublicData]) => {
          let tags = [];

          console.timeEnd('fetching');

          this.data = playerData
            ? { ...playerData, team: { ...playerData.team, plan: publicData.plan } }
            : ({} as PlayerData);

          this.data.questions = (this.data.questions || []).map((question, i) => ({
            $key: i.toString(),
            ...question,
          }));
          this.data.outcomes = (this.data.outcomes || []).map((outcome, i) => ({
            $key: i.toString(),
            ...outcome,
          }));
          this.data.triggers = (this.data.triggers || []).map((trigger, i) => ({
            $key: i.toString(),
            ...trigger,
          }));

          console.log('Survey data', this.data);

          if (!window.ZEF || this.route.snapshot.params.public === 'demo') {
            this.team = 'zef';
            this.survey = 'demo';
            this.pollSession = '';
          } else {
            this.team = window.ZEF.team;
            this.survey = window.ZEF.survey;
            this.pollSession = this.queryParams.get('poll_session') || '';
          }

          if (this.data.survey) {
            this.data.survey.$key = this.survey;
          }

          if (this.restoreState) {
            this.playerState = this.playerSessionState?.[this.survey] || null;
          }

          console.time('preparing');

          if (window.ZEF && window.ZEF.tags) {
            tags = (window.ZEF.tags || '').split(',');
          }

          if (this.data?.survey?.language) {
            document.documentElement.setAttribute('lang', this.data.survey.language);
          }

          // TODO: Now the release url is only set in index.html, if we want
          //       it to work in js embedding then lets move it to zefembed.

          const url = window.ZEF ? window.ZEF.url : '';
          const link = window.ZEF ? window.ZEF.link : 'demo';

          this.tm.init({
            team: this.team,

            triggers: this.data.triggers || [],
            questions: this.data.questions || [],
          });

          this.am
            .init(this.team, this.survey, link, tags, this.pollSession)
            .pipe(first())
            .subscribe(() => {
              console.timeEnd('preparing');
              this.data.answers = this.am.getAllAnswers(this.data);
              this.setAnswersFromRoute();

              this.isAnonymous =
                !url.includes('#') &&
                this.am.isAnonymous() &&
                !playerData.questions.some((q: QuestionData) => q.type === Questions.INPUT_EMAIL);

              this.initializationDone.next(true);
              observer.next('loaded');
              observer.complete();
            });
        });
    });
  }

  public reset() {
    this.am.shutdown(true);
    this.tm.shutdown();
    // this.initializationDone.next(false);
  }

  public onPlayerStateChange(state: PlayerState): void {
    if (state.view === 'welcome') {
      this.accessibilityBannerHide = 0;
    }

    if (this.accessibilityBannerHide < 3 && (state.view === 'welcome' || state.view === 'questions')) {
      this.accessibilityBannerHide += 1;

      if (this.accessibilityBannerHide === 2) {
        this.showAccessibilityBanner.next(false);
      }
    }

    if (this.restoreState) {
      this.playerSessionState = {
        ...(this.playerSessionState || {}),
        [this.survey]: state,
      };
    }
  }

  public onShowResults(): void {
    if (this.pollSession) {
      this.tm.checkTriggers(true);
      this.am.saveData().subscribe(() => {
        window.location.href = `${environment.pollAddress}/${this.am.getLinkKey()}/${this.pollSession}/results?lang=${
          this.lm.currentLanguage
        }`;
      });
    } else {
      window.setTimeout(() => {
        this.am.saveData();

        window.setTimeout(() => {
          this.tm.checkTriggers(true);
        }, 1000);
      }, 2000);
    }
  }

  public onResetAnswers(): void {
    this.am.clearAnswers();

    this.data.answers = this.am.getAllAnswers(this.data);

    this.tm.reset();

    this.showAccessibilityBanner.next(true);
    this.accessibilityBannerHide = 0;
  }

  public onActiveChanged(active: PlayerAnswer): void {
    this.am.setAnswer(active);
  }

  public onAnswerChanged(answer: PlayerAnswer): void {
    this.am.setAnswer(answer);

    if (answer.item?.$key !== 'zefSurveyUserRating') {
      this.tm.reset();
      setTimeout(() => this.tm.checkTriggers(false));
    }

    this.data.answers = this.am.getAllAnswers(this.data);
  }

  public onResultsChanged(results: PlayerOutcome[]): void {
    this.am.setPlayerResults(results);

    this.ps.scoredOutcomes.next(results);
  }

  private setAnswersFromRoute(): void {
    const questions = this.data.questions || [];
    const queryParams = this.queryParams.keys().reduce(
      (params, key) => ({
        ...params,
        [key]: this.queryParams.get(key),
      }),
      {} as Record<string, string>,
    );

    Object.entries(queryParams)
      .filter(([key]) => !!key && this.data.answers[key] == null && questions.some((question) => question.$key === key))
      .forEach(([key, value]) => {
        const question = questions.find((q) => q.$key === key);
        const answer: PlayerAnswer = { item: question, value: value != null ? value : null };
        const answers = { ...this.data.answers, [key]: value };

        const progress = Object.keys(answers).length > 0 ? questions.map(({ $key }) => answers[$key] != null) : [];
        const answered = progress.filter(Boolean);
        answer.progress = answered.length / progress.length;

        this.onAnswerChanged(answer);
      });
  }
}
