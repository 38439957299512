<div class="z-fx-col z-fx-start-center z-fx-gap-16 why-finder-container">
  <div class="z-fx-col z-fx-auto z-fx-gap-8 messages">
    <ng-container *ngFor="let message of messages">
      <div
        *ngIf="message.role === 'assistant' || (message.role === 'user' && questionData?.whyFinder?.maxQuestions !== 1)"
        class="message"
        [class.interviewee]="message.role === 'user'"
        [class.interviewer]="message.role === 'assistant'"
      >
        {{ message?.message }}
      </div>
    </ng-container>
  </div>
  <div *ngIf="loading$ | async" class="dot-typing" [style.--text-color]="(ss.colors | async).text"></div>
  <div *ngIf="!messages?.length && (loading$ | async) === false" class="z-fx z-fx-center-center">
    <mat-icon class="error-icon" fontSet="zefplayer">placeholder</mat-icon>
  </div>
  <ng-container *ngIf="questionData?.whyFinder?.maxQuestions !== 1; else simpleInterview">
    <div *ngIf="showInput" class="z-fx z-fx-none z-fx-gap-4 input-area">
      <mat-form-field class="field" floatLabel="never">
        <textarea
          matInput
          autocomplete="off"
          autoSize
          [autoSizeMinRows]="4"
          name="free-text"
          [maxLength]="getCharsLimit()"
          #input
          [placeholder]="placeholder || ''"
          [value]="''"
          [attr.aria-label]="placeholder || ''"
          (keyup.enter)="addComment(input.value); input.value = ''"
        ></textarea>
      </mat-form-field>
      <button class="send-button" (click)="addComment(input.value); input.value = ''">
        <mat-icon fontSet="zefplayer">send</mat-icon>
      </button>
    </div>
  </ng-container>
</div>

<ng-template #simpleInterview>
  <div class="z-fx z-fx-none input-area">
    <mat-form-field class="field" floatLabel="never">
      <textarea
        matInput
        autocomplete="off"
        autoSize
        [autoSizeMinRows]="4"
        name="free-text"
        [maxLength]="getCharsLimit()"
        #simpleInput
        [placeholder]="placeholder || ''"
        [attr.aria-label]="placeholder || ''"
        [value]="simpleAnswer"
        (blur)="saveSimpleAnswer(simpleInput.value)"
      ></textarea>
    </mat-form-field>
  </div>
</ng-template>
