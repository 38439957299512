/**
 * CSS styles related data models.
 *
 * @unstable
 */

import tinycolor from 'tinycolor2';

import { FontInterface } from 'ngx-font-picker';

export class FontStyles implements FontInterface {
  url: string = '//fonts.googleapis.com/icon?family=Open+Sans:400,600,700&display=swap';
  size: string = '14px';
  style: string = 'regular';
  weight: string = 'normal';
  family: string = 'Inter';

  getStyles(color?: string, viewSize?: string): any {
    let size = this.size;

    if (viewSize === 'sm') {
      size = parseInt(size, 10) - 2 + 'px';
    } else if (viewSize === 'xs' || viewSize === 'tn') {
      size = parseInt(size, 10) - 4 + 'px';
    }

    const styles: any = {
      'font-size': size,
      'font-weight': this.weight,
      'font-family': `"${this.family}"`,
    };

    if (color != null) {
      styles['color'] = color;
    }

    return styles;
  }
}

export abstract class ImageStyleData {
  url: string = '';
  mode: string = 'cover';
  align: string = 'center center';
  color: string = 'transparent';
  thumb: string = '';
  source: string = '';
}

export class ImageStyles extends ImageStyleData {
  blur: number = 0;
  tint: number = 0;
  light: number = 0;

  getImage(): string {
    return this.url && 'url(' + encodeURI(this.url) + ')';
  }

  getFilter(): string {
    return `blur(${this.blur}px)`;
  }

  getBrightnessColor(): string {
    if (!this.light) {
      return '';
    }

    return tinycolor(this.light > 0 ? '#FFF' : '#000')
      .setAlpha(Math.abs(this.light) / 100)
      .toRgbString();
  }

  getStyles(): object {
    return {
      'background-size': this.mode,
      'background-color': this.color,
      'background-repeat': `no-repeat`,
      'background-position': this.align,
    };
  }

  getImageStyles(): object {
    return {
      'object-fit': this.mode,
      'background-color': this.color,
    };
  }
}

export class ColorStyles {
  mood: string = '#ffffff';
  texts: string = '#495c6c';
  primary: string = '#0083ff';
  buttons: string = '#ef5d65';

  opacity: number = 0.2;
}

export function mixColors(color1: string, color2: string, amount?: number): string {
  return tinycolor.mix(tinycolor(color1), tinycolor(color2), amount).toRgbString();
}

export function parityColor(color: string) {
  return !color ? null : tinycolor(color).getLuminance() > 0.6 ? '#000' : '#fff';
}

export function contrastColor(color: string) {
  return !color ? null : tinycolor(color).getLuminance() < 0.6 ? '#000' : '#fff';
}

export function desaturatedColor(color: string, value: number = 0) {
  return !color
    ? null
    : tinycolor(color)
        .desaturate(value * 100)
        .toRgbString();
}

export function transparentColor(color: string, value: number = 1) {
  return !color ? null : tinycolor(color).setAlpha(value).toRgbString();
}

export function opaqueColor(color: string, value: number = 1) {
  return !color
    ? null
    : tinycolor(color)
        .setAlpha(1 - value)
        .toRgbString();
}

export class ThemeStyles {
  url: string = '';
}
