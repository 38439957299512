/**
 * General zef api class for zef services.
 *
 * @unstable
 */

import { environment } from '@env/environment';

import { BehaviorSubject, interval, Observable, of, Subject, throwError, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  finalize,
  map,
  mapTo,
  shareReplay,
  switchMap,
  take,
  tap,
  timeout,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { Requests } from '@shared/enums/requests.enum';
import { Answerer, GetAnswerIdResponse } from '@player/shared/models/player.model';
import { AnswererData } from '@shared/models/answer.model';

export interface RequestParams {
  path: string;
  method: Requests;

  body?: any;
  params?: any;

  beacon?: boolean;

  withCredentials?: boolean;

  reportProgress?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PlayerApi {
  get clientCountry(): string {
    return this._clientCountry;
  }

  private baseUrl: string = `https:${environment.apiServer}/`;
  private proxyUrl: string = `https:${environment.bckServer}/`;

  private readonly headers = new HttpHeaders({
    'Environment': environment.config,
    'Content-Type': 'application/json',
  });

  private readonly phoneValidations: Record<string, Observable<boolean>> = {};

  private _clientCountry = 'fi';

  private readonly uploads: Record<string, { subject: BehaviorSubject<number>; file: File }> = {};
  private readonly deletes: Record<string, Observable<void>> = {};

  private readonly uploadTrigger = new Subject<string>();
  private readonly deleteTrigger = new Subject<string>();

  readonly uploadTrigger$ = this.uploadTrigger.asObservable();
  readonly deleteTrigger$ = this.deleteTrigger.asObservable();

  constructor(readonly http: HttpClient) {
    if (window.location.href.includes('zefProxy=true')) {
      this.baseUrl = this.proxyUrl;
    }

    if (window.ZEF && window.ZEF.version && window.ZEF.version !== 'default') {
      const version = window.ZEF.version.replace('x', '0').replace(/\./g, '-');

      this.baseUrl = this.baseUrl.replace('//', '//' + version + '.');
    }
  }

  getAnswererId(survey: string, session: string, params: object, isDemo: boolean): Observable<GetAnswerIdResponse> {
    const answerer$ = isDemo
      ? of({ answerer: { id: survey, key: survey }, countryCode: 'nl' } as GetAnswerIdResponse)
      : this.get<GetAnswerIdResponse>(`save/get_answerer_id/${survey}/${session}`, params, true);

    return answerer$.pipe(
      tap((response) => {
        this._clientCountry = response.countryCode || this._clientCountry;
        if (window.ZEF && (response?.restartDelay || response?.restartInactive)) {
          window.ZEF.restartDelay = response.restartDelay || null;
          window.ZEF.restartInactive = response.restartInactive || null;
        }
      }),
      concatMap((response) => (response && response.answerer ? of(response) : throwError(response))),
    );
  }

  validatePhone(country: string, number: string): Observable<boolean> {
    if (!number || !country) {
      return of(true);
    }

    const validationKey = `${country};${number}`;
    const validation = this.phoneValidations[validationKey];

    if (!validation) {
      this.phoneValidations[validationKey] = this.get<{ valid: boolean; error: ProgressEvent }>(
        'save/validate_phone_number',
        { country, number },
      ).pipe(
        map((response) => {
          if (!response || response.error) {
            delete this.phoneValidations[validationKey];

            return true;
          }

          return !!response.valid;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }

    return this.phoneValidations[validationKey];
  }

  uploadFile(
    params: { question: string; team?: string; survey?: string; link?: string; session?: any; answerer?: Answerer },
    fake?: boolean,
  ): Observable<{ answerer: AnswererData } | void> {
    const upload = this.uploads[params.question];

    if (!upload) {
      return of(void 0);
    }
    const form = new FormData();
    form.append('data', JSON.stringify(params));
    form.append('file', upload.file);

    let request: Observable<{ answerer: AnswererData } | void>;

    if (params.survey === 'demo' || fake) {
      request = interval(100).pipe(
        map((i) => (2.5 / 100) * i),
        tap((progress) => upload.subject.next(progress)),
        filter((progress) => progress >= 1),
        take(1),
        delay(1000),
        tap(() => upload.subject.next(2)),
        mapTo(void 0),
      );
    } else {
      request = this.http
        .request(Requests.Post, `${this.baseUrl}save/upload`, {
          body: form,
          headers: new HttpHeaders({
            Environment: environment.config,
          }),
          observe: 'events',
          responseType: 'json',
          withCredentials: false,
          reportProgress: true,
        })
        .pipe(
          tap((event) => {
            switch (event.type) {
              case HttpEventType.Sent:
                return upload.subject.next(0);
              case HttpEventType.UploadProgress:
                return upload.subject.next(event.loaded / event.total);
              case HttpEventType.Response:
                return event.status === 200 ? upload.subject.next(2) : upload.subject.next(-1);
            }
          }),
          filter((event) => event.type === HttpEventType.Response),
          map((response: HttpResponse<{ answerer: AnswererData }>) => response.body),
          catchError((e) => {
            upload.subject.next(-1);
            upload.subject.error(e);
            return of(null);
          }),
        );
    }

    return (this.deletes[params.question] || of(void 0)).pipe(
      switchMap(() => request),
      finalize(() => {
        upload.subject.complete();
        delete this.uploads[params.question];
      }),
    );
  }

  uploadFileRaw(
    params: { question: string; team?: string; survey?: string; link?: string; session?: any; answerer?: Answerer },
    file: File,
  ): Observable<any> {
    const form = new FormData();
    form.append('data', JSON.stringify(params));
    form.append('file', file);

    return this.http
      .request(Requests.Post, `${this.baseUrl}save/upload`, {
        body: form,
        headers: new HttpHeaders({
          Environment: environment.config,
        }),
        observe: 'events',
        responseType: 'json',
        withCredentials: false,
        reportProgress: true,
      })
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<{ answerer: AnswererData }>) => response.body),
      );
  }

  uploadFileProgress(key: string, file: File): Observable<number> {
    const upload = this.uploads[key];

    if (upload) {
      upload.subject.complete();
    }

    this.uploads[key] = { file, subject: new BehaviorSubject<number>(0) };

    this.uploadTrigger.next(key);

    return this.uploads[key].subject.asObservable();
  }

  triggerDeleteUpload(key: string): void {
    const upload = this.uploads[key];

    if (upload) {
      upload.subject.complete();
      delete this.uploads[key];
    }

    this.deleteTrigger.next(key);
  }

  deleteUpload(
    params: { question: string; respondent?: any; team?: string; survey?: string; session?: string },
    fake?: boolean,
  ): Observable<void> {
    const request = params.survey === 'demo' || fake ? timer(100) : this.delete('save/upload', params);

    return (this.deletes[params.question] = request.pipe(finalize(() => delete this.deletes[params.question])));
  }

  getPendingUploads(): File[] {
    return Object.keys(this.uploads).map((key) => this.uploads[key].file);
  }

  getFileUploadProgress(key: string): Observable<number | undefined> {
    return this.uploads[key]?.subject.asObservable() || of(void 0);
  }

  getFileUpload(key: string): { file: File; progress$: Observable<number> } {
    const upload = this.uploads[key];

    if (upload) {
      return {
        file: upload.file,
        progress$: upload.subject.asObservable(),
      };
    }
  }

  postAIrequest<T = any>(path: string, body: any = {}, headerData: any = {}): Observable<T> {
    let headers = this.headers;

    if (headerData?.team) {
      headers = headers.append('Active-Team', headerData.team);
    }
    if (headerData?.survey) {
      headers = headers.append('Survey-Key', headerData.survey);
    }

    return this.http
      .request<T>(Requests.Post, `${this.baseUrl}${path}`, {
        body,
        headers,
        responseType: 'json',
        withCredentials: false,
      })
      .pipe(
        timeout(60000),
        catchError(() => {
          this.baseUrl = this.proxyUrl;

          console.log('Falling back to using proxy for the backend');

          return this.http
            .request<T>(Requests.Post, `${this.proxyUrl}${path}`, {
              body,
              headers: this.headers,
              responseType: 'json',
              withCredentials: false,
            })
            .pipe(
              timeout(300000),
              catchError((error: any) => throwError(error)),
            );
        }),
      );
  }

  get<T = any>(path: string, params: object = {}, withCredentials = false): Observable<T> {
    return this.request<T>({ method: Requests.Get, path, params, withCredentials });
  }

  post<T = any>(path: string, data: any = {}, beacon?: boolean, params: object = {}): Observable<T> {
    return this.request<T>({ method: Requests.Post, path, body: data, params, beacon });
  }

  delete<T = any>(path: string, params: object = {}): Observable<T> {
    return this.request<T>({ method: Requests.Delete, path, params });
  }

  private request<T = any>({ method, path, body, params, withCredentials, beacon }: RequestParams): Observable<T> {
    let url = `${path}`;

    if (beacon) {
      if (environment.config) {
        const prepend = url.includes('?') ? '&' : '?';
        url = `${prepend}environment=${environment.config}`;
      }

      navigator.sendBeacon?.(`${this.baseUrl}${url}`, JSON.stringify(body || {}));

      return of(void 0);
    } else {
      return this.http
        .request<T>(method, `${this.baseUrl}${url}`, {
          body,
          headers: this.headers,
          responseType: 'json',
          params: new HttpParams({ fromObject: params || {} }),
          withCredentials: withCredentials || false,
        })
        .pipe(
          timeout(60000),
          catchError(() => {
            this.baseUrl = this.proxyUrl;

            console.log('Falling back to using proxy for the backend');

            return this.http
              .request<T>(method, `${this.proxyUrl}${url}`, {
                body,
                headers: this.headers,
                responseType: 'json',
                params: new HttpParams({ fromObject: params || {} }),
                withCredentials: withCredentials || false,
              })
              .pipe(
                timeout(300000),
                catchError((error: any) => throwError(error)),
              );
          }),
        );
    }
  }
}
