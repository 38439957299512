import { BehaviorSubject, catchError, combineLatest, map, of, takeWhile, throwError } from 'rxjs';

import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InterviewMessageData } from '@player/shared/models/player.model';

import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyAssistant } from '@player/shared/services/survey-assistant.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { QuestionData } from '@shared/models/survey.model';

import { getLastValue } from '@shared/operators/share-ref.operator';
import { AIModelDependentMessage } from '@shared/utilities/string.utilities';

@Directive()
export class WhyFinderBase<T = string> implements OnInit {
  @Input() answer: T = null;
  @Input() errorText = 'Error...';
  @Input() isActive = false;
  @Input() isLast = false;
  @Input() placeholder = '';
  @Input() questionData: QuestionData = null;

  @Input()
  set answerValue(value: T | null) {
    this._answerValue = value;

    this.messages =
      value
        ?.toString()
        .split(this.separator)
        .map((message) => {
          if (message === 'isCompleted:true') {
            this.interviewDone = true;
            return null;
          }
          return message;
        })
        .filter(
          (message) =>
            message?.length > 12 &&
            (message.substring(0, 12) === 'Interviewee:' || message.substring(0, 12) === 'Interviewer:'),
        )
        .map((message) => {
          return {
            role: message.substring(0, 12) === 'Interviewee:' ? 'user' : 'assistant',
            message: message.slice(12),
          };
        }) || [];

    this.showInput =
      getLastValue(this.targetQuestionAnswer) != null &&
      ((!this.interviewDone &&
        (this.questionData?.whyFinder?.maxQuestions || 5) -
          this.messages.filter((m) => m?.role === 'assistant').length >=
          0) ||
        this.messages.slice(-1)?.[0]?.role === 'assistant');

    this.simpleAnswer =
      this.questionData?.whyFinder?.maxQuestions === 1
        ? this.messages.find((msg) => msg.role === 'user')?.message || ''
        : '';
    this.cdRef.detectChanges();
  }

  get answerValue(): T | null {
    return this._answerValue;
  }

  @Output() answerChange: EventEmitter<string> = new EventEmitter();
  @Output() answering = new EventEmitter<boolean>();
  @Output() answerReady = new EventEmitter<void>();
  @Output() saveSummaries = new EventEmitter<any>();
  @Output() nextQuestion = new EventEmitter<void>();

  private _answerValue: T | null = null;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public simpleSaveInProgress$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public simpleAnswer: string = '';
  public messages: InterviewMessageData[] = [];
  public showInput: boolean = true;
  public interviewDone: boolean = false;
  public showAllMessages: boolean = false;

  readonly targetQuestionAnswer = this.ss.answers.pipe(
    map((answers) => answers?.[this.questionData?.whyFinder?.target]),
  );
  readonly targetQuestion = this.ss.questions.pipe(
    map((questions) => questions.find((q) => q.$key === this.questionData?.whyFinder?.target)),
  );
  readonly metaData = combineLatest([this.ss.team, this.ss.survey]).pipe(
    map(([team, survey]) => ({ team: team?.$key, survey: survey?.$key })),
  );
  readonly separator: string = '\u001D';

  constructor(
    readonly sa: SurveyAssistant,
    readonly ss: SurveyStore,
    readonly lm: LanguageManager,
    readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const answerChanged: boolean =
      this.sa.whyFinderTargetAnswers?.hasOwnProperty(this.questionData.$key) &&
      this.sa.whyFinderTargetAnswers[this.questionData.$key] !== getLastValue(this.targetQuestionAnswer);
    const isSingleQuestion = this.questionData?.whyFinder?.maxQuestions === 1;
    const noMessages = this.messages?.length === 0;
    const shouldShowInput = this.showInput || !this.interviewDone;
    const hasWhyFinder = this.sa.whyFinders?.[this.questionData.$key];
    const lastMessageNotAssistant = this.messages[this.messages.length - 1]?.role !== 'assistant';

    if (isSingleQuestion) {
      if (noMessages || answerChanged) {
        this.initInterviewer(true, answerChanged);
      }
    } else if (shouldShowInput && !hasWhyFinder) {
      this.initInterviewer(lastMessageNotAssistant);
    } else if (shouldShowInput && answerChanged) {
      this.initInterviewer(true, true);
    }
  }

  trackByMessage(index: number, message: InterviewMessageData): string {
    return message?.message;
  }

  initInterviewer(ask: boolean, reset: boolean = false): void {
    const language: string = this.lm.languages?.[this.lm.currentLanguage]?.name || 'English';

    if (reset) {
      this.messages = [];
    }

    this.loading$.next(true);
    this.ss.disableScroll.next(true);
    this.sa
      .initWhyFinder(
        this.questionData,
        getLastValue(this.targetQuestion),
        getLastValue(this.targetQuestionAnswer),
        language,
        this.messages,
        ask,
        getLastValue(this.metaData),
      )
      .pipe(
        catchError((error: any) => {
          this.loading$.next(false);
          this.ss.disableScroll.next(false);
          return throwError(error);
        }),
      )
      .subscribe((result) => {
        const message = AIModelDependentMessage(result);

        if (message) {
          this.messages.push({
            role: 'assistant',
            message: message,
          });
          this.answerChange.emit(
            (!reset ? (this.answerValue || '') + (this.answerValue ? this.separator : '') : '') +
              'Interviewer:' +
              message,
          );
        }

        this.loading$.next(false);
        this.ss.disableScroll.next(false);
        this.cdRef.detectChanges();
      });
  }

  getCharsLimit(): number {
    return (this.questionData && this.questionData.charsLimit) || 15000;
  }

  addComment(comment: string): void {
    const actualComment = comment.trim();

    if (actualComment) {
      this.answerChange.emit(
        (this.answerValue || '') + (this.answerValue ? this.separator : '') + 'Interviewee:' + actualComment,
      );

      if (
        (this.questionData?.whyFinder?.maxQuestions || 5) -
          this.messages.filter((m) => m?.role === 'assistant').length >=
        0
      ) {
        this.loading$.next(true);
        this.ss.disableScroll.next(true);

        this.sa
          .nextWhyFinderQuestion(this.questionData, { role: 'user', message: comment })
          .pipe(
            catchError((error: any) => {
              this.loading$.next(false);
              this.ss.disableScroll.next(false);
              return throwError(error);
            }),
          )
          .subscribe((result) => {
            const message = AIModelDependentMessage(result);

            if (message) {
              this.messages.push({
                role: 'assistant',
                message: message,
              });
              this.answerChange.emit(
                (this.answerValue || '') + (this.answerValue ? this.separator : '') + 'Interviewer:' + message,
              );
            }

            if (
              this.messages.filter((m) => m?.role === 'assistant').length >= 0 &&
              this.messages.filter((m) => m?.role === 'user').length >= 0
            ) {
              this.getAndSaveSummaries();
            }

            this.loading$.next(false);
            this.ss.disableScroll.next(false);
            this.cdRef.detectChanges();
          });
      }
    }
  }

  getAndSaveSummaries(simpleSave?: boolean, nextQuestion?: boolean): void {
    const defaulLanguage: string = this.lm.languages?.[this.lm.defaultLanguage]?.name || 'English';
    combineLatest([
      !simpleSave
        ? this.sa.getWhyFinderSummary(this.questionData, defaulLanguage)
        : this.sa.getWhyFinderSummary(this.questionData, defaulLanguage, [
            { role: 'user', message: this.simpleAnswer },
          ]),
      !simpleSave ? this.sa.getWhyFinderFinishButton(this.questionData) : of(null),
    ])
      .pipe(
        catchError((error: any) => {
          this.simpleSaveInProgress$.next('');
          this.ss.disableScroll.next(false);
          return throwError(error);
        }),
      )
      .subscribe(([content, showButton]) => {
        const isJSON: (string) => boolean = (str) => {
          try {
            JSON.parse(str);
          } catch (e) {
            return false;
          }
          return true;
        };
        const parsedContent = isJSON(content?.[0]?.message?.function_call?.arguments)
          ? JSON.parse(content[0].message.function_call.arguments)
          : null;
        const parsedShowButton = isJSON(showButton?.[0]?.message?.function_call?.arguments)
          ? JSON.parse(showButton[0].message.function_call.arguments)
          : null;
        const maxQuestionsReached: boolean =
          (this.questionData?.whyFinder?.maxQuestions || 5) +
            1 -
            this.messages.filter((m) => m?.role === 'assistant').length ===
          0;

        if (!this.interviewDone && (parsedShowButton?.showButton || maxQuestionsReached || simpleSave)) {
          this.interviewDone = parsedShowButton?.showButton || simpleSave;

          this.answerChange.emit(
            (this.answerValue || '') + (this.answerValue ? this.separator : '') + 'isCompleted:true',
          );
        }

        if (parsedContent) {
          for (const key in parsedContent) {
            parsedContent[key] = Array.isArray(parsedContent[key]) ? parsedContent[key].join(';') : parsedContent[key];
          }

          this.saveSummaries.emit(parsedContent);
        }

        if (simpleSave) {
          this.simpleSaveInProgress$.next('');
          this.ss.disableScroll.next(false);

          if (nextQuestion) {
            this.nextQuestion.emit();
          }
        }
      });
  }

  saveSimpleAnswer(comment: string, nextQuestion?: boolean): void {
    if (this.simpleSaveInProgress$.value === this.questionData.$key) {
      this.simpleSaveInProgress$.pipe(takeWhile((v) => !!v, true)).subscribe((val) => {
        if (!val) {
          this.saveSimpleAnswerFunction(comment, nextQuestion);
        }
      });
    } else {
      this.saveSimpleAnswerFunction(comment, nextQuestion);
    }
  }

  saveSimpleAnswerFunction(comment: string, nextQuestion?: boolean): void {
    const actualComment = comment.trim();
    const previousComment = this.messages.find((msg) => msg.role === 'user')?.message || '';

    if (actualComment && actualComment !== previousComment) {
      this.simpleAnswer = actualComment;
      this.ss.disableScroll.next(true);
      this.simpleSaveInProgress$.next(this.questionData.$key);

      this.answerChange.emit(
        (this.answerValue.toString() || '').split(this.separator)[0] +
          (this.answerValue ? this.separator : '') +
          'Interviewee:' +
          actualComment,
      );
      this.getAndSaveSummaries(true, nextQuestion);
    } else if (nextQuestion) {
      this.nextQuestion.emit();
    }
  }

  onValueChanged(): void {
    /* This seems to trigger the needed change detection for input value in template */
  }
}
